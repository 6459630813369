import React from 'react'

const Content = () => {
    return (
        <>
            <section className="banner" id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-8">
                            <div className="header-content wow fadeInUp">

                                <h2>Make your life <br />More easy</h2>

                                <div className="download-button">
                                    <a href="https://apps.apple.com/us/app/can-do/id6447361663" target='_blank' className="btn-download"><i className="fa fa-apple"></i> <span>Download on the</span>App Store</a>
                                    <a href="https://play.google.com/store/apps/details?id=ca.cando" target='_blank' className="btn-download"><i className="fa fa-android"></i> <span>GET IT ON</span>Google Play</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="slider-image wow fadeInRight">
                                <img src="/images/header-image.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Advertising</h2>
                                <ul>
                                    <li>
                                        <p>Our advertising application is packed with powerful features to help you create and manage successful ad campaigns. Here are just a few of the features you can expect:</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="feature-single wow fadeInUp" data-wow-delay="0.2s">
                                {/* <div className="icon-box">
                                    <img src="/images/feature-1.png" alt="" />
                                </div> */}

                                <h3>Targeted advertising</h3>
                                <p>With our application, you can target your ads to specific demographics, locations, and interests, ensuring that your message reaches the right audience.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="feature-single wow fadeInUp" data-wow-delay="0.4s">
                                {/* <div className="icon-box">
                                    <img src="/images/feature-2.png" alt="" />
                                </div> */}

                                <h3>Customizable templates</h3>
                                <p>Our application comes with a range of customizable templates that make it easy to create stunning ads in minutes, without any design experience required.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="feature-single wow fadeInUp" data-wow-delay="0.6s">
                                {/* <div className="icon-box">
                                    <img src="/images/feature-3.png" alt="" />
                                </div> */}

                                <h3>Advanced analytics</h3>
                                <p>Our analytics tools provide detailed insights into your ad performance, allowing you to track your results, and optimize your strategy for maximum impact.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="feature-single wow fadeInUp" data-wow-delay="0.6s">
                                {/* <div className="icon-box">
                                    <img src="/images/feature-3.png" alt="" />
                                </div> */}

                                <h3>Automated bidding</h3>
                                <p>Our automated bidding system ensures that you get the best possible price for your ad placements, while still reaching your target audience.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="feature-single wow fadeInUp" data-wow-delay="0.6s">
                                {/* <div className="icon-box">
                                    <img src="/images/feature-3.png" alt="" />
                                </div> */}

                                <h3>Ad scheduling</h3>
                                <p>With our ad scheduling feature, you can choose when and where your ads are shown, so you can reach your audience at the optimal time.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="feature-single wow fadeInUp" data-wow-delay="0.6s">
                                {/* <div className="icon-box">
                                    <img src="/images/feature-3.png" alt="" />
                                </div> */}

                                <h3>Easy campaign management</h3>
                                <p>Our user-friendly interface and intuitive design make it easy to manage your ad campaigns, track your results, and adjust your strategy on the fly.</p>
                            </div>
                        </div>

                        <ul>
                            <li>
                                <p>These are just a few of the features that our advertising application offers. With our platform, you can create and manage successful ad campaigns with ease, and achieve your business goals in no time.</p>
                            </li>
                        </ul>

                    </div>
                </div>
            </section>

            <section className="awesome-feature" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Awesome Features</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="awesome-feature-single wow fadeInUp" data-wow-delay="0.2s">
                                <div className="icon-box">
                                    <img src="/images/features-1.png" alt="" />
                                </div>

                                <h3>Connect With Friends</h3>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="awesome-feature-single wow fadeInUp" data-wow-delay="0.4s">
                                <div className="icon-box">
                                    <img src="/images/features-2.png" alt="" />
                                </div>

                                <h3>Online Support</h3>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="awesome-feature-single wow fadeInUp" data-wow-delay="0.6s">
                                <div className="icon-box">
                                    <img src="/images/features-3.png" alt="" />
                                </div>

                                <h3>Best Pricing Plan</h3>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="awesome-feature-single wow fadeInUp" data-wow-delay="0.8s">
                                <div className="icon-box">
                                    <img src="/images/features-4.png" alt="" />
                                </div>

                                <h3>Notification</h3>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="awesome-feature-single wow fadeInUp" data-wow-delay="1.0s">
                                <div className="icon-box">
                                    <img src="/images/features-5.png" alt="" />
                                </div>

                                <h3>Pixel Perfect</h3>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="awesome-feature-single wow fadeInUp" data-wow-delay="1.2s">
                                <div className="icon-box">
                                    <img src="/images/features-6.png" alt="" />
                                </div>

                                <h3>High Resolution</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="how-work" id="howitwork">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>How it Works</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="how-work-image wow fadeIn">
                                <img src="/images/how-it-work.png" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="how-work-tab">
                                <ul className="nav nav-tabs responsive hidden-xs hidden-sm" id="tabs-0">
                                    <li className="active"><a href="#download-app">Download</a></li>
                                </ul>

                                <div className="tab-content responsive hidden-xs hidden-sm">
                                    <div className="tab-pane active fade in" id="download-app">
                                        <h3>Download For Free</h3>

                                        <p>Looking for an easier way to buy and sell products on-the-go? Look no further than our mobile application! Available now for download on the App Store and Google Play, our application is the ultimate destination for advertising, buying and selling.</p>

                                        <p>With a simple and intuitive user interface, our application makes it easy to browse ads, connect with other users, and complete transactions securely and conveniently. Whether you're looking to buy a new product or sell something you no longer need, our application has everything you need to get started.</p>

                                        <p>And with features like notifications, secure login and signup, and compatibility with multiple platforms, our application is designed to make your mobile advertising, buying and selling experience as easy and hassle-free as possible. Plus, our application is available in multiple languages and regions, so you can use it no matter where you are in the world.</p>

                                        <p>So why wait? Download our mobile application today and start enjoying the ultimate mobile advertising, buying and selling experience!</p>

                                        <div className="download-button">
                                            <a href="https://apps.apple.com/us/app/can-do/id6447361663" target='_blank' className="btn-download"><i className="fa fa-apple"></i> <span>Download on the</span>App Store</a>
                                            <a href="https://play.google.com/store/apps/details?id=ca.cando" target='_blank' className="btn-download"><i className="fa fa-android"></i> <span>GET IT ON</span>Google Play</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Online buying and selling</h2>
                            </div>
                            <ul>
                                <li>
                                    <p>Welcome to Can-Do , the ultimate platform for online buying and selling on the go. Our mobile app makes it easy for you to buy and sell assets, manage your portfolio, and stay up-to-date with the latest market trends.</p>
                                </li>
                                <li>
                                    <p>With Can-Do, you can market a wide range of products and services. Our user-friendly interface and intuitive design make it easy to navigate, so you can quickly find the products you want to buy ands sell and execute your transactions with ease.</p>
                                </li>
                                <li>
                                    <p>But that's not all. Our mobile app also offers a range of powerful features to help you manage your portfolio and stay on top of the market. From customizable watchlists to real-time market data, we've got everything you need.</p>
                                </li>
                                <li>
                                    <p>Plus, our mobile app is safe and secure, with advanced encryption and authentication protocols to protect your sensitive information and transactions.</p>
                                </li>
                                <li>
                                    <p>So why wait? Download Can-Do today and start buying and selling on the go.</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <ul>
                        <li>
                            <p>Welcome to our mobile application - the ultimate destination for all your advertising, product and service needs! Our application is designed to make it easy for you to buy and sell products, connect with other users, and discover new products to enhance your lifestyle. Here are some of the key features that you can expect to find in our application:</p>
                        </li>
                    </ul>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel-group" id="accordion">
                                <div className="panel">
                                    <div className="panel-heading active">
                                        <h4 className="panel-title"><a href="#collapse1" data-toggle="collapse" data-parent="#accordion">Intuitive User Interface</a></h4>
                                    </div>

                                    <div id="collapse1" className="panel-collapse collapse in">
                                        <div className="panel-body">
                                            <p>Our application is designed to be intuitive and easy to use, with a clean and modern user interface that is consistent throughout the app.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse2" data-toggle="collapse" data-parent="#accordion">Secure Login and Signup</a></h4>
                                    </div>

                                    <div id="collapse2" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>You can create a new account or log in with your existing credentials, and our application uses state-of-the-art security features to protect your data.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse3" data-toggle="collapse" data-parent="#accordion">Advertising Features</a></h4>
                                    </div>

                                    <div id="collapse3" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Our application allows you to view and interact with ads from a variety of sources, including third-party advertising networks, so that you can find the products that you need quickly and easily.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse4" data-toggle="collapse" data-parent="#accordion">Buying and selling Features</a></h4>
                                    </div>

                                    <div id="collapse4" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>You can buy and sell products through our application, with a simple and secure checkout process that makes it easy to complete transactions.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse5" data-toggle="collapse" data-parent="#accordion">Notifications</a></h4>
                                    </div>

                                    <div id="collapse5" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Our application sends you notifications for important events, such as successful buying and selling or new advertisements, so that you never miss an opportunity to connect with other users.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse6" data-toggle="collapse" data-parent="#accordion">Security Features</a></h4>
                                    </div>

                                    <div id="collapse6" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Our application uses encryption and authentication to protect your data, so you can rest assured that your information is safe and secure.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse7" data-toggle="collapse" data-parent="#accordion">Performance</a></h4>
                                    </div>

                                    <div id="collapse7" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Our application is designed to perform well on a variety of devices and network speeds, with fast load times and minimal lag.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse8" data-toggle="collapse" data-parent="#accordion">Compatibility</a></h4>
                                    </div>

                                    <div id="collapse8" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Our application is compatible with a variety of mobile platforms, so you can use it on your favorite device.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse9" data-toggle="collapse" data-parent="#accordion">Localization</a></h4>
                                    </div>

                                    <div id="collapse9" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Our application is available in multiple languages and regions, so you can use it no matter where you are in the world.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title collapsed"><a href="#collapse10" data-toggle="collapse" data-parent="#accordion">Usability</a></h4>
                                    </div>

                                    <div id="collapse10" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>We've conducted extensive usability testing to ensure that our application is easy to use and meets your needs, and we're constantly gathering feedback from users to improve our features and functionality.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <ul>
                                <li>
                                    <p>We hope you enjoy using our mobile application, and we look forward to connecting you with the products and people that matter most to you!</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>

            <section className="screenshot" id="screenshot">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Our Application</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="screenshot-slider">
                                <div style={{ width: 310, height: 480, marginTop: 5 }} className="screenshot-phone">
                                    <img src="/images/phone.png" alt="" />
                                </div>

                                <div className="owl-carousel owl-theme screenshot-slider zoom-screenshot" id="screenshot-slider-carousel">
                                    <div className="item">
                                        <div className="screenshot-single">
                                            <img src="/images/screenshot-1.jpg" alt="" />
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="screenshot-single">
                                            <img src="/images/screenshot-2.jpg" alt="" />
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="screenshot-single">
                                            <img src="/images/screenshot-3.jpg" alt="" />
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="screenshot-single">
                                            <img src="/images/screenshot-4.jpg" alt="" />
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="screenshot-single">
                                            <img src="/images/screenshot-5.jpg" alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing" id="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Package & Pricing</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="pricing-title wow fadeInUp" data-wow-delay="0.2s">
                                <h3>Upgrade Three Circle to premium and unlock all potential of the App</h3>
                                <a href="#howitwork" className="btn-custom">Unlock The App</a>
                                {/* <p>To get free 14 days Premium trial you must be registered. If you do not continue with Premium after 14 days, your account will be revert to the Started profile.</p> */}
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="pricing-single wow fadeInUp" data-wow-delay="0.4s">
                                <div className="pricing-header">
                                    <h3>Premium</h3>
                                    <h4><del>$ 2.0</del> Free <span>/ Month</span></h4>
                                </div>

                                <div className="pricing-body">
                                    <ul>
                                        <li>Show all advertise and posts</li>
                                        <li>Chat with all users</li>
                                        <li>Call to all users</li>
                                        <li>Put 4 Advertise in every month</li>
                                    </ul>
                                </div>

                                <div className="pricing-footer">
                                    <a href="#howitwork" className="btn-custom">Buy Now</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="pricing-single wow fadeInUp" data-wow-delay="0.6s">
                                <div className="pricing-header">
                                    <h3>Business</h3>
                                    <h4><del>$ 20.0</del> Free <span>/ Month</span></h4>
                                </div>

                                <div className="pricing-body">
                                    <ul>
                                        <li>Show all advertise and posts</li>
                                        <li>Chat with all users</li>
                                        <li>Call to all users</li>
                                        <li>Put Unlimited Advertise in every month</li>
                                        <li>Put 10 Posts in every month</li>
                                    </ul>
                                </div>

                                <div className="pricing-footer">
                                    <a href="#howitwork" className="btn-custom">Buy Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Get In Touch</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="contact-form">
                                <form id="contactForm" action="#" method="post" data-toggle="validator">
                                    <div className="form-group">
                                        <input type="text" name="name" id="name" className="form-control" placeholder="Enter Your Name" required />
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="form-group">
                                        <input type="email" name="email" id="email" className="form-control" placeholder="Your Email Address" required />
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="form-group">
                                        <input type="text" name="subject" id="subject" className="form-control" placeholder="Subject" required />
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="form-group">
                                        <textarea rows="8" name="message" id="message" className="form-control" placeholder="How can i help you?" required></textarea>
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="text-center">
                                        <button type="submit" className="btn-contact" title="Submit Your Message!">Submit</button>
                                        <div id="msgSubmit" className="h3 text-left hidden"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Content
