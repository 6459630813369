import React from 'react'

const Header = () => {
    return (
        <>
            <header>
                <nav id="main-nav" className="navbar navbar-default navbar-fixed-top">
                    <div className="container">
                        <div className="navbar-header">
                            <a className="navbar-brand" href="index.html" >
                                <img src="/images/logo.png" alt="Logo" />
                            </a>

                            <div className="navbar-toggle">
                            </div>
                        </div>
                        <div id="responsive-menu"></div>
                        <div className="navbar-collapse collapse" id="navigation">
                            <ul className="nav navbar-nav navbar-right main-navigation" id="main-menu">
                                <li className="active"><a href="#home">Home</a></li>
                                <li><a href="#features">Advertising</a></li>
                                <li><a href="#howitwork">How it Work</a></li>
                                <li><a href="#screenshot">Screenshot</a></li>
                                <li><a href="#pricing">Pricing</a></li>
                                <li><a href="#contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header
