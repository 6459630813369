import React from 'react'

const Footer = () => {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-logo">
                                <a href="#"><img src="/images/footer-logo.png" alt="" /></a>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="footer-menu">
                                <ul>
                                    <li><a style={{color:'blue'}} href="mailto:sales@can-do.ca">sales@can-do.ca</a></li>
                                    {/* <li><a href="#">Help</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Terms of Service</a></li> */}
                                </ul>
                            </div>

                            <div className="footer-siteinfo">
                                <p>&copy; 2022 Can-Do. All Rights Reserved</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="footer-social">
                                <a href="#"><i className="fa fa-facebook"></i></a>
                                <a href="#"><i className="fa fa-twitter"></i></a>
                                <a href="#"><i className="fa fa-linkedin"></i></a>
                                <a href="#"><i className="fa fa-pinterest-p"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
