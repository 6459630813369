import { I18n } from "i18n-js";

import en from './en.json';
import fa from './fa.json';

const i18n = new I18n({
    en: en,
    fa: fa,
});
i18n.defaultLocale = 'en';

export const initLang = () => {
    const lang = localStorage.getItem('lang');
    const rtl = localStorage.getItem('rtl');
    if (!lang)
        localStorage.setItem('lang', 'en');

    if (!rtl)
        localStorage.setItem('rtl', false);

    i18n.locale = localStorage.getItem('lang');
}

export const setLanguage = (lang) => {
    i18n.locale = lang;
    localStorage.setItem('lang', lang);
    if (lang === 'fa')
        localStorage.setItem('rtl', true);
    else
        localStorage.setItem('rtl', false);
    window.location.reload();
}

export const strings = (name, params = {}) => {
    return i18n.t(name, params);
}