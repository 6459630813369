import React, { useState } from 'react'
// import './styles/addFont.css';
// import './styles/style.css';
import { initLang } from './utils/locales/i18n';
import PreLoader from './components/PreLoader';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';

const App = () => {

  const [rtl] = useState(localStorage.getItem('rtl'));
  initLang();

  return (
    // <div className={rtl === 'true' ? ('rtl') : ('')}>
    <>
      <Helmet>
        <script defer type="text/javaScript" src="./js/jquery-1.12.4.min.js"></script>
        <script defer type="text/javaScript" src="./js/bootstrap.min.js"></script>
        <script defer type="text/javaScript" src="./js/responsive-tabs.js"></script>
        <script defer type="text/javaScript" src="./js/validator.min.js"></script>
        <script defer type="text/javaScript" src="./js/owl.carousel.js"></script>
        <script defer type="text/javaScript" src="./js/jquery.slicknav.js"></script>
        <script defer type="text/javaScript" src="./js/wow.js"></script>
        <script defer type="text/javaScript" src="./js/jquery.magnific-popup.min.js"></script>
        <script defer type="text/javaScript" src="./js/SmoothScroll.js"></script>
        <script defer type="text/javaScript" src="./js/function.js"></script>
      </Helmet>
      {/* <PreLoader /> */}
      <Header />
      <Content />
      <Footer />
    </>
  )
}

export default App